import axiosClient from "./axiosClient";

const getContactList = (limit = 10, page = 1, q) => {
  let url = `/contact/v2?limit=${limit}&page=${page}`;
  if (q) {
    url += `&q=${q}`;
  }
  return axiosClient.get(url);
};

const createContactFilter = (contactFilterParams) => {
  const url = "/user-filter";
  return axiosClient.post(url, contactFilterParams);
};

const getContactFilter = () => {
  const url = "/user-filter";
  return axiosClient.get(url);
};

const updateContactFilter = ({ id, contactFilterParams }) => {
  const url = `/user-filter/${id}`;
  return axiosClient.put(url, contactFilterParams);
};

const deleteContactFilter = (id) => {
  const url = `/user-filter/${id}`;
  return axiosClient.delete(url);
};

const getContactListByFilterId = (id) => {
  const url = `/contact/filter/${id}`;
  return axiosClient.get(url);
};

const importContactList = (contactList) => {
  const url = "/contact/importContact";
  return axiosClient.post(url, contactList);
};

// Create a new alert
const createAlert = (alertData) => {
  const url = "/alert";
  return axiosClient.post(url, alertData);
};

// Get the list of alerts
const getAlertList = () => {
  const url = "/alert";
  return axiosClient.get(url);
};

// Update an existing alert
const updateAlert = ({ id, alertData }) => {
  const url = `/alert/${id}`;
  return axiosClient.put(url, alertData);
};

// Delete an alert
const deleteAlert = (alertId) => {
  const url = `/alert/${alertId}`;
  return axiosClient.delete(url);
};

const getContactListByAlertId = (id, limit = 10, page = 1, q) => {
  let url = `/contact/alert/${id}?limit=${limit}&page=${page}`;
  if (q) {
    url += `&q=${q}`;
  }
  return axiosClient.get(url);
};

const getSatisfiedContactsByAlertIdWithinGivenContacts = (
  id,
  contactIds = []
) => {
  const contactIdsArrayQuery = contactIds.reduce((acc, contactId) => {
    return `${acc}&cid[]=${contactId}`;
  }, "");
  // On backend side, if limit go above 100, it will return 100 contacts max which is equal to largest rows per page of backoffice pagination
  const url = `/contact/alert/${id}?limit=1000&disableCountTotal=true${contactIdsArrayQuery}`;
  return axiosClient.get(url);
};

const getAlertMatchingHistories = (fromDate, toDate, period = "MONTHLY") => {
  const url = `/contact/alert/matching-history?fromDate=${fromDate}&toDate=${toDate}&period=${period}`;
  return axiosClient.get(url);
};

const createAlertIgnorance = ({ userId, alertId, startTime, endTime }) => {
  const url = `/alert/ignorance`;
  return axiosClient.post(url, { userId, alertId, startTime, endTime });
};

const deleteAlertIgnorance = (ignoranceId) => {
  const url = `/alert/ignorance/${ignoranceId}`;
  return axiosClient.delete(url);
};

const getIgnoredAlertsByUser = (userId) => {
  const url = `/alert/ignorance?userId=${userId}`;
  return axiosClient.get(url);
};

export {
  getContactList,
  createContactFilter,
  getContactFilter,
  getContactListByFilterId,
  updateContactFilter,
  deleteContactFilter,
  importContactList,
  createAlert,
  getAlertList,
  updateAlert,
  deleteAlert,
  getContactListByAlertId,
  getSatisfiedContactsByAlertIdWithinGivenContacts,
  getAlertMatchingHistories,
  createAlertIgnorance,
  deleteAlertIgnorance,
  getIgnoredAlertsByUser,
};

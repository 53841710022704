import { IContactAlert } from "./contact-alert-item";
import { useSnackbar } from "notistack";
import { useCallback, useContext, useMemo, useState } from "react";
import { useAlertIgnoranceListByUser, useCreateAlertIgnorance, useDeleteAlertIgnorance } from "../../hooks/contact.hook";
import {
  Checkbox,
  Dialog,
  DialogActions,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import {CustomOutlineButton} from "../../components/custom-button";
import CustomButton from "../../components/custom-button";
import AlertHitItem from "./alert-hit-item";
import { TContactContext } from "../../context/contact-context.type";
import ContactContext from "../../context/contact-context";
import { addMonths, format } from 'date-fns'; // Import addMonths from date-fns
import { useQueryClient } from "react-query";

type UserIgnoredAlert = {
  id: string;
  userId: string;
  alertId: string;
  startTime: string;
  endTime: string;
  createdAt: string;
  updatedAt: string;
  updatedBy?: string;
  createdBy?: string;
};

type CreateAlertIgnorance = {
    userId: string;
    alertId: string;
    startTime: string;
    endTime: string;
}

type ContactWithIgnoranceData = IContactAlert & {
  ignoranceData?: UserIgnoredAlert;
  isTriggered?: boolean;
};

type IgnoranceType = "SNOOZE" | "DISMISS";

interface ReviewAlertsDialogProps {
  isOpen: boolean;
  handleOpenDialog: () => void;
  alertList: IContactAlert[];
  contactAlertMap?: Record<string, IContactAlert[]>;
}

const AlertIgnoranceItem: React.FC<{
  data: ContactWithIgnoranceData;
  onIgnoranceOptionChange: (
    alertId: string,
    ignoranceType: IgnoranceType | null
  ) => Promise<void>;
}> = ({ data, onIgnoranceOptionChange }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { selectedContactAlert } = useContext<TContactContext>(
    ContactContext
  );
  const options: Array<{ value: IgnoranceType; label: string }> = [
    {
      value: "DISMISS",
      label: "Mark as done (will reset in 6 months)",
    },
    {
      value: "SNOOZE",
      label: "Snooze for 30 days",
    },
  ];
  const [optionsChecked, setOptionsChecked] = useState<boolean[]>(
    options.map(() => false)
  );

  const handleIgnoranceOptionChange = useCallback(
    (e, index: number) => {
      const checked = e.target.checked;
      if (checked) {
        const checked = options.map((_, i) => {
          return i === index;
        });
        setOptionsChecked(checked);
      } else {
        setOptionsChecked(options.map(() => false));
      }
      onIgnoranceOptionChange(data.id, checked ? options[index].value : null);
    },
    [data, onIgnoranceOptionChange, setOptionsChecked, options]
  );
  const ignored = useMemo(() => {
    if (!data.ignoranceData) return false;
    const currentTime = new Date();
    return currentTime >= new Date(data.ignoranceData.startTime) && currentTime <= new Date(data.ignoranceData.endTime);
  }, [data])

  const showEndTime = useMemo(() => {
    if (!data?.ignoranceData?.endTime) return null
    return format(new Date(data.ignoranceData.endTime), 'yyyy-MM-dd HH:mm:ss');
  }, [data]);

  const deleteIgnorance = useDeleteAlertIgnorance();
  const handleDeleteIgnorance = useCallback(async (ignoranceData: UserIgnoredAlert) => {
    const { id, userId } = ignoranceData;
    deleteIgnorance.mutateAsync(id, {
      onSuccess: () => {
        enqueueSnackbar("Ignorance deleted", { variant: "success" });
        queryClient.invalidateQueries("satisfiedContactsByAlertId")
        queryClient.invalidateQueries("alertList");
        queryClient.invalidateQueries(["alertIgnoranceListByUser", userId]);
        if (selectedContactAlert) {
            queryClient.invalidateQueries(["contactListByAlertId", selectedContactAlert]);
        } else {
            queryClient.invalidateQueries("contactList");
        }
      },
      onError: () => {
        enqueueSnackbar("Failed to delete ignorance", { variant: "error" });
      },
    });
  }, [deleteIgnorance]);

  return (
    <div className="alert-ignorance-item">
      <AlertHitItem
        alert={data as IContactAlert}
        faded={!data?.isTriggered}
        customClassName="alert-ignorance-item__alert"
      />
      { !ignored && (<div className="actions">
        <div className="title">Actions</div>
        <FormGroup>
          {options.map((option, index) => {
            return (
              <FormControlLabel
                checked={optionsChecked[index]}
                control={<Checkbox />}
                label={option.label}
                key={`${data.id}-${option.value}`}
                onChange={(e) => handleIgnoranceOptionChange(e, index)}
              />
            );
          })}
        </FormGroup>
      </div>
      )}
      { ignored && (
        <div className="deletion">
          <div>Ignored until {showEndTime}</div>
          <CustomButton
            label="Delete"
            buttonType="error"
            onClick={() => {
              handleDeleteIgnorance(data.ignoranceData);
            }}
          />
        </div>
      )}
    </div>
  );
};

const ignoranceDurationInMonth: Record<IgnoranceType, number> = {
    SNOOZE: 1,
    DISMISS: 6,
  }

export const ReviewAlertsDialog: React.FC<ReviewAlertsDialogProps> = ({
  alertList = [],
  handleOpenDialog = () => {},
  contactAlertMap = {},
  isOpen,
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedReviewAlertUserId: userId, selectedContactAlert } = useContext<TContactContext>(
    ContactContext
  );
  const { data: ignoredAlertList } = useAlertIgnoranceListByUser(userId);
  const alertListWithIgnoranceStatus = useMemo<
    Array<ContactWithIgnoranceData>
  >(() => {
    // INFO: set this to false if you want to include untriggered alerts
    const removeUntriggered = true;

    const triggeredAlertList = contactAlertMap[userId] || [];
    const unsort = alertList.map((alert) => {
      const ignoranceData = ignoredAlertList?.find(
        (ignoredAlert) => ignoredAlert.alertId === alert.id
      );
      const isTriggered = triggeredAlertList?.some(item => item.id === alert.id);
      return {
        ...alert,
        ignoranceData,
        isTriggered,
      };
    });
    // sort by isTriggered, if true will be on top
    const sorted = unsort.sort((a, b) => {
      if (a.isTriggered && !b.isTriggered) return -1;
      if (!a.isTriggered && b.isTriggered) return 1;
      return 0;
    });

    return removeUntriggered
      ? sorted.filter((alert) => alert.isTriggered)
      : sorted;
  }, [alertList, ignoredAlertList, userId, contactAlertMap]);

  // handle create ignorance
  const [ignoranceCreationMap, setIgnoranceCreationMap] = useState(new Map<string, string>)
  const handleIgnoranceOptionChange = useCallback((alertId: string, ignoranceType: IgnoranceType | null) => {
    if (ignoranceType) {
      setIgnoranceCreationMap((prev) => {
        return new Map(prev.set(alertId, ignoranceType));
      });
    } else {
      setIgnoranceCreationMap((prev) => {
        prev.delete(alertId);
        return new Map(prev);
      });
    }
  }, [setIgnoranceCreationMap])

  const createAlertIgnorance = useCreateAlertIgnorance(); 
  const handleCreateAlertIgnorance = useCallback(async () => {
    const result: CreateAlertIgnorance[] = [];
    ignoranceCreationMap.forEach((type, id) => {
        const startTime = new Date();
        const duration = ignoranceDurationInMonth[type];
        const endTime = addMonths(startTime, duration);
        result.push({
            userId,
            alertId: id,
            startTime: startTime.toISOString(),
            endTime: endTime.toISOString(),
        });
    });
    try {
        await Promise.all(
            result.map((ignorance) => {
                return createAlertIgnorance.mutateAsync(ignorance);
            })
        )
        setIgnoranceCreationMap(new Map());
        enqueueSnackbar("Ignorance created", { variant: "success" });
        queryClient.invalidateQueries("alertList");
        queryClient.invalidateQueries("satisfiedContactsByAlertId")
        queryClient.invalidateQueries(["alertIgnoranceListByUser", userId]);
        if (selectedContactAlert) {
            queryClient.invalidateQueries(["contactListByAlertId", selectedContactAlert]);
        } else {
            queryClient.invalidateQueries("contactList");
        }
        handleOpenDialog();
    } catch (error) {
        enqueueSnackbar("Failed to create ignorance", { variant: "error" });
    }
  }, [ignoranceCreationMap, userId, createAlertIgnorance]);
  return (
    <Dialog
      onClose={() => handleOpenDialog()}
      aria-labelledby="review-alert-dialog-aria-label"
      className="review-alert-dialog"
      open={isOpen}
    >
      <div id="review-alerts-dialog-body-container">
        <div className="review-alert-dialog__title">Alerts</div>
        {alertListWithIgnoranceStatus?.length && (
          <div className="alert-ignorance-list">
            {alertListWithIgnoranceStatus.map((alert) => {
              return (
                <AlertIgnoranceItem
                  key={`${userId}-${alert.id}`}
                  data={alert}
                  onIgnoranceOptionChange={async (alertId, ignoranceType) => {
                    handleIgnoranceOptionChange(alertId, ignoranceType);
                }}
                />
              );
            })}
          </div>
        )}
        <DialogActions className="alert-ignorance-actions">
          <CustomOutlineButton onClick={() => handleCreateAlertIgnorance()} label="Save" buttonType="secondary" />
          <CustomOutlineButton onClick={() => handleOpenDialog()} label="Cancel" buttonType="dark" />
        </DialogActions>
      </div>
    </Dialog>
  );
};

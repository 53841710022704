import { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Divider,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { isSameMonth, parse, subMonths } from "date-fns";
import { findLast } from "lodash";
import { useSnackbar } from "notistack";
import { getAppUrl } from "../../api-services/axiosClient";
import HeaderComponent from "../../components/HeaderComponent";
import { CustomOutlineButton } from "../../components/custom-button";
import ContactContext from "../../context/contact-context";
import ContactAlert from "../../features/contact/contact-alert.tsx";
import ContactTable from "../../features/contact/contact-table";
import ShareDetailsDialog from "../../features/contact/share-details-dialog";
import {
  useAlertList,
  useAlertMatchingHistories,
  useContactList,
  useCreateAlert,
  useGetContactListByAlert,
  useGetSatisfiedContactsByAlertsWithinGivenContacts,
  useUpdateAlert,
  useImportContactlist,
} from "../../hooks/contact.hook";
import { monthDayFormater } from "../../utils/calendar-util";
import {
  formatNumberWithDollar,
  formatNumberWithPercent,
} from "../../utils/numberFormater";
import { currentAuthenticatedUser } from "../../utils/user-auth-provider";
import EmailEditorDialog from "../../features/contact/email-editor-dialog";
import ContactAlertDialog from "../../features/contact/contact-alert-dialog.tsx";
import { useIsCompanyAdmin, useMyInfo } from "../../hooks/user.hooks";
import { useCreateEmail } from "../../hooks/email.hooks";
import ImportContactButton from "../../features/contact/import-contact-btn";
import FilePreviewDialog from "../../features/contact/file-preview-dialog";
import { AlertMatchingHistorySection } from "../../features/contact/alert-matching-history-section.tsx";
import { ContactSearchInput } from "../../features/contact/contact-search-input.tsx";
import { ALERT_CHART_MOCK_DATA } from "../../utils/mock-data";
import { primaryColor } from "../../scss/colors.module.scss";
import { ReviewAlertsDialog } from "../../features/contact/review-alerts-dialog.tsx";

const formatContactNumber = (value) => {
  return typeof value === "number" || value === 0
    ? formatNumberWithDollar(value)
    : "Not Available";
};

const formatContactRatio = (value) => {
  return typeof value === "number" || value === 0
    ? formatNumberWithPercent(value)
    : "Not Available";
};

const columns = [
  { id: "checkbox", label: "" },
  { id: "contactName", label: "Name", minWidth: 170 },
  { id: "contactDetail", label: "Details" },
  {
    id: "propertiesEstimatedValue",
    label: "Property EST",
    format: formatContactNumber,
    minWidth: 140,
  },
  {
    id: "debtOutstanding",
    label: "Debt",
    format: formatContactNumber,
  },
  {
    id: "lender",
    label: "Lender",
  },
  {
    id: "debtInterestRate",
    label: "Rate",
    format: formatContactRatio,
  },
  {
    id: "cashAvailable",
    label: "Cash",
    format: formatContactNumber,
  },
  {
    id: "equity",
    label: "Equity",
    format: formatContactNumber,
  },
  {
    id: "minBuyingGoal",
    label: "Buying Goal",
    format: formatContactNumber,
    minWidth: 140,
  },
  {
    id: "maxBuyingPower",
    label: "Buying Power",
    format: formatContactNumber,
    minWidth: 140,
  },
  {
    id: "alerts",
    label: "Alerts",
  },
  {
    id: "actionButton",
    label: "Actions",
  },
];

const createData = ({
  id,
  contactName,
  contactDetail,
  maxBuyingPower,
  minBuyingPower,
  cashAvailable,
  debtOutstanding,
  lender,
  debtInterestRate,
  lastAppVisit,
  lvr,
  maxLoanAmount,
  currentInterestRate,
  minBuyingGoal,
  maxBuyingGoal,
  hasMetBuyingGoal,
  hasMetSavingGoal,
  excessMonthlySurplus,
  equity,
  propertiesEstimatedValue,
  handleViewAccount,
  handleShareDetail,
  handleCheckAccount,
  handleReviewAlerts,
  isContactHasTriggeredAnyAlerts,
  lastLoginTime,
}) => {
  return {
    id,
    contactName,
    contactDetail,
    maxBuyingPower,
    minBuyingPower,
    cashAvailable,
    debtOutstanding,
    lender,
    debtInterestRate,
    lastContact: lastAppVisit || "Not Available",
    lastAppVisit: lastAppVisit || "Not Available",
    checkbox: <Checkbox onClick={handleCheckAccount} />,
    lastLoginTime: lastLoginTime || "Not Available",
    lvr,
    maxLoanAmount,
    currentInterestRate,
    minBuyingGoal,
    maxBuyingGoal,
    hasMetBuyingGoal,
    hasMetSavingGoal,
    excessMonthlySurplus,
    equity,
    propertiesEstimatedValue,
    actionButton: (
      <div className="contact-table__action-button-container">
        <CustomOutlineButton
          label="View Account"
          onClick={handleViewAccount}
          buttonType="secondary"
        />
        <CustomOutlineButton
          label="Share Details"
          onClick={handleShareDetail}
          buttonType="secondary"
        />
        {isContactHasTriggeredAnyAlerts(id) && (
          <CustomOutlineButton
            label="Review Alerts"
            onClick={handleReviewAlerts}
            buttonType="secondary"
          />
        )}
      </div>
    ),
  };
};

const ContactPage = () => {
  const { data: myInfo } = useMyInfo();
  const isShowDemoDatasets = useMemo(() => {
    const email = myInfo?.email || "";
    const emails = (
      process.env.GATSBY_SHOW_DEMO_ALERT_CHART_EMAILS || ""
    ).split(",");
    return emails.includes(email);
  }, [myInfo]);

  const { enqueueSnackbar } = useSnackbar();
  const { data: isCompanyAdmin } = useIsCompanyAdmin();
  const queryClient = useQueryClient();
  const [checkedEmails, setCheckedEmails] = useState([]);

  const [contactPage, setContactPage] = useState(1);
  const [contactLimit, setContactLimit] = useState(10);
  const [searchContact, setSearchContact] = useState(null);
  const onContactSearch = useCallback(
    (searchQuery) => {
      setSearchContact(searchQuery);
      setContactPage(1);
    },
    [setSearchContact]
  );
  const useContactListQuery = useContactList(
    contactLimit,
    contactPage,
    searchContact
  );
  const {
    data: { data: contactList = [], total: totalGeneralContacts = 0 } = {},
    refetch,
    isFetching: isGeneraralContactFetching,
  } = useContactListQuery;
  const onContactTableChange = (limit, page) => {
    setContactLimit(limit);
    setContactPage(page);
  };

  // --------- share detail logic section ------------
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenEmailEditorDialog, setIsOpenEmailEditorDialog] = useState(false);
  const [isOpenShareDetailDialog, setIsOpenShareDetailDialog] = useState(false);
  const [selectedContact, setSelectedContact] = useState("");
  const [period, setPeriod] = useState(0);
  const [createContactDTOs, setCreateContactDTOs] = useState([]);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isRetry, setIsRetry] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const createEmail = useCreateEmail();
  const menuItems = [
    {
      value: -1,
      label: "Unsubscribe",
    },
    {
      value: 0,
      label: "Send once off",
    },
    {
      value: 7 * 4,
      label: `Every 4 weeks`,
    },
    {
      value: 7 * 6,
      label: `Every 6 weeks`,
    },
    {
      value: 7 * 8,
      label: `Every 8 weeks`,
    },
    {
      value: 7 * 12,
      label: `Every 12 weeks`,
    },
  ];

  const handleViewAccount = async (userId) => {
    const authUser = await currentAuthenticatedUser();
    const authUserName = authUser.username;
    window.open(
      `${getAppUrl()}/?viewAsUserId=${userId}&authUserName=${authUserName}`
    );
  };

  const handleDialog = () => {
    setIsOpenDialog(!isOpenDialog);
  };

  const handleShareDetailDialog = useCallback(
    (id) => {
      setSelectedContact(id);
      setIsOpenShareDetailDialog(!isOpenShareDetailDialog);
    },
    [isOpenShareDetailDialog]
  );

  const handleEmailEditorDialog = (event) => {
    setPeriod(event.target.value);
    if (event.target.value === -1) {
      createEmail.mutate(
        {
          emailList: checkedEmails,
          templateId: null,
          period: -1,
        },
        {
          onSuccess: () => {
            refetch();
            enqueueSnackbar(`Unsubscribe`, {
              variant: "success",
              autoHideDuration: 2000,
            });
          },
          onError: (err) => {
            enqueueSnackbar(err.message, {
              variant: "Error unsubscribing",
            });
          },
        }
      );
    } else {
      setIsOpenEmailEditorDialog(!isOpenEmailEditorDialog);
    }
  };

  const handleCheckAccount = useCallback((email, event) => {
    setCheckedEmails((prevCheckedEmail) => {
      if (event.target.checked) {
        return [...prevCheckedEmail, email];
      }
      return prevCheckedEmail.filter((checkedEmail) => checkedEmail !== email);
    });
  }, []);
  // --------- share detail logic section ------------

  // review alert by user id section
  const [selectedReviewAlertUserId, setSelectedReviewAlertUserId] = useState(
    null
  );
  const [isReviewAlertDialogOpen, setIsReviewAlertDialogOpen] = useState(false);
  const handleReviewAlerts = useCallback(
    (userId) => {
      if (userId) {
        setSelectedReviewAlertUserId(userId);
      }
      setIsReviewAlertDialogOpen(!isReviewAlertDialogOpen);
    },
    [isReviewAlertDialogOpen]
  );

  // --------- alert logic section ------------
  // alertData is for storing data on edit or create
  const [alertData, setAlertData] = useState(null);
  // selectedContactAlert is for storing selected alert id
  const [selectedContactAlert, setSelectedContactAlert] = useState(null);
  // is selecting the total alert item (first item)
  const [isAlertTotalItemSelected, setIsAlertTotalItemSelected] = useState(
    true
  );
  const {
    data: { data: contactListByAlert = [], total: totalContactsByAlert } = {},
    isSuccess: isFetchContactByAlertSuccess,
    isFetching: isContactByAlertFetching,
  } = useGetContactListByAlert(
    selectedContactAlert,
    contactLimit,
    contactPage,
    searchContact
  );

  // create/edit modal
  const [isOpenAlertDialog, setIsOpenAlertDialog] = useState(false);

  const handleSelectContactAlert = (isTotalItem, alert) => {
    setContactPage(1);
    setIsAlertTotalItemSelected(isTotalItem);
    setSelectedContactAlert(alert?.id);
    setAlertData(alert);
    if (selectedContactAlert) {
      queryClient.invalidateQueries(["contactList", contactLimit, contactPage]);
    } else {
      queryClient.invalidateQueries([
        "contactListByAlertId",
        selectedContactAlert,
        contactLimit,
        contactPage,
      ]);
    }
  };
  const handleAlertDialog = () => {
    setIsOpenAlertDialog(!isOpenAlertDialog);
  };

  // UI show
  const { data: alerts } = useAlertList({
    onError: () => {
      enqueueSnackbar("Failed to get alerts", {
        variant: "error",
      });
    },
  });

  const totalAlertHit = useMemo(() => {
    return alerts?.reduce((acc, alert) => {
      return acc + (alert.total || 0);
    }, 0);
  }, [alerts]);

  const totalAlertHitLastMonth = useMemo(() => {
    return alerts?.reduce((acc, alert) => {
      return acc + (alert.totalLastMonth || 0);
    }, 0);
  }, [alerts]);

  const createAlert = useCreateAlert();
  const handleCreateAlert = () => {
    createAlert.mutate(alertData, {
      onSuccess: (data) => {
        queryClient.invalidateQueries("alertList");
        setSelectedContactAlert(data.alertId);
        setIsAlertTotalItemSelected(false);
        handleAlertDialog();
      },
      onError: (error) => {
        enqueueSnackbar(error?.data?.message, {
          variant: "error",
        });
      },
    });
  };

  const updateAlert = useUpdateAlert();
  const handleUpdateAlert = () => {
    updateAlert.mutate(
      {
        id: alertData.id,
        alertData,
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries("alertList");
          if (selectedContactAlert === data.alertId) {
            setSelectedContactAlert(null);
          }
          setSelectedContactAlert(data.alertId);
          setIsAlertTotalItemSelected(false);
          handleAlertDialog();
        },
        onError: (error) => {
          enqueueSnackbar(error?.data?.message, {
            variant: "error",
          });
        },
      }
    );
  };

  const currentPageContactIds = useMemo(() => {
    const contactListData =
      selectedContactAlert && isFetchContactByAlertSuccess
        ? contactListByAlert
        : contactList;
    return contactListData.map((contact) => contact.id);
  }, [
    contactList,
    contactListByAlert,
    selectedContactAlert,
    isFetchContactByAlertSuccess,
  ]);
  // --------- contacts map for alert hits ------------
  const contactAlertsMap = useGetSatisfiedContactsByAlertsWithinGivenContacts(
    alerts,
    currentPageContactIds
  );

  const isContactHasTriggeredAnyAlerts = useCallback(
    (userId) => {
      return contactAlertsMap[userId]?.length > 0;
    },
    [contactAlertsMap]
  );

  // --------- alert logic section ------------

  const totalContacts = useMemo(() => {
    return selectedContactAlert && isFetchContactByAlertSuccess
      ? totalContactsByAlert
      : totalGeneralContacts;
  }, [
    selectedContactAlert,
    isFetchContactByAlertSuccess,
    totalContactsByAlert,
    totalGeneralContacts,
  ]);

  const buildContactListData = useMemo(() => {
    const contactListData =
      selectedContactAlert && isFetchContactByAlertSuccess
        ? contactListByAlert
        : contactList;
    return contactListData.map((contact) => {
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        lastLoginTime,
        id,
        maxBuyingPower,
        minBuyingPower,
        cashAvailable,
        debtOutstanding,
        debtInterestRates,
        lenderNames,
        lenderShortNames,
        lvr,
        maxLoanAmount,
        currentInterestRate,
        maxBuyingGoal,
        minBuyingGoal,
        hasMetBuyingGoal,
        hasMetSavingGoal,
        excessMonthlySurplus,
        equity,
        propertiesEstimatedValue,
        createdAt,
      } = contact;
      const contactName = `${firstName} ${lastName}`.trim();
      const isLastLoginAvailable = !!lastLoginTime;
      let lender = "No lender";
      if (lenderNames?.length) {
        lender = lenderShortNames[0] ? lenderShortNames[0] : lenderNames[0];
      }
      const debtInterestRate = debtInterestRates?.[0];

      return createData({
        id,
        contactName,
        contactDetail: {
          email,
          phoneNumber,
        },
        maxBuyingPower,
        minBuyingPower,
        cashAvailable,
        debtOutstanding,
        lender,
        debtInterestRate,
        lastAppVisit: isLastLoginAvailable
          ? monthDayFormater(new Date(lastLoginTime))
          : monthDayFormater(new Date(createdAt)),
        lvr,
        maxLoanAmount,
        currentInterestRate,
        minBuyingGoal,
        maxBuyingGoal,
        hasMetBuyingGoal,
        hasMetSavingGoal,
        excessMonthlySurplus,
        equity,
        propertiesEstimatedValue,
        handleViewAccount: () => handleViewAccount(id),
        handleShareDetail: () => handleShareDetailDialog(id),
        handleCheckAccount: (event) => handleCheckAccount(email, event),
        handleReviewAlerts: () => handleReviewAlerts(id),
        isContactHasTriggeredAnyAlerts,
      });
    });
  }, [
    contactList,
    contactListByAlert,
    handleShareDetailDialog,
    handleCheckAccount,
    isFetchContactByAlertSuccess,
    selectedContactAlert,
    handleReviewAlerts,
    isContactHasTriggeredAnyAlerts,
  ]);

  const parseCSV = (text) => {
    return text
      .trim()
      .split("\n")
      .slice(1)
      .map((row, index) => {
        const [firstName, lastName, email, phoneNumber] = row.split(",");
        return {
          id: index,
          phoneNumber: phoneNumber.trim(),
          email: email.trim(),
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          isImported: null,
        };
      });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const contactDTO = parseCSV(text);
      setCreateContactDTOs(contactDTO);
      setIsOpenPreview(true);
    };
    // eslint-disable-next-line no-param-reassign
    event.target.value = null;
    reader.readAsText(file);
  };

  const handleOpenDialog = () => {
    setIsRetry(false);
    setIsOpenPreview(!isOpenPreview);
  };

  const importContact = useImportContactlist();

  const handleSubmit = (dtoList) => {
    setIsLoading(true);
    const filteredDTOs = isRetry
      ? dtoList.filter((dto) => !dto.isImported)
      : dtoList;
    setIsRetry(false);

    importContact.mutate(filteredDTOs, {
      onSuccess: (data) => {
        setIsSnackbarOpen(true);
        const insertCount = data.contactList.filter((row) => row.isImported)
          .length;
        setSnackMessage(`${insertCount} contacts inserted`);
        setIsLoading(false);
        if (data.isSuccess) {
          refetch();
          setIsOpenPreview(false);
          return;
        }
        setCreateContactDTOs(
          data.contactList.filter((contact) => !contact.isImported)
        );
        setIsRetry(true);
      },
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSnackbarOpen(false);
  };
  // --------- charts data ------------
  const [chartPeriod] = useState("MONTHLY");
  const [numOfPeriods] = useState(12);
  const {
    data: alertMatchingHistories,
    isError,
    isMatchingAlertLoading,
  } = useAlertMatchingHistories(chartPeriod, numOfPeriods);
  const alertChartData = useMemo(() => {
    if (isShowDemoDatasets) {
      return ALERT_CHART_MOCK_DATA;
    }
    return alertMatchingHistories;
  }, [isShowDemoDatasets, alertMatchingHistories]);
  // reload chart data on alert changes
  useEffect(() => {
    queryClient.invalidateQueries(["alertMatchingHistories"]);
  }, [alerts, queryClient]);
  const getLatestPeriodDataOfCurrentAndLastMonth = (data) => {
    const now = new Date();
    const currentMonth = now;
    const lastMonth = subMonths(now, 1);

    const parsedData = data.map((item) => ({
      ...item,
      date: parse(item.period, "yyyy-MM-dd", new Date()),
    }));

    const latestCurrentMonthItem = findLast(parsedData, (item) =>
      isSameMonth(item.date, currentMonth)
    );
    const latestLastMonthItem = findLast(parsedData, (item) =>
      isSameMonth(item.date, lastMonth)
    );

    return {
      latestCurrentMonthItem,
      latestLastMonthItem,
    };
  };
  const alertMatchingTotalSummaries = useMemo(() => {
    if (!alertChartData) return [];
    const { needAction, onWatch, healthy } = alertChartData;
    const {
      latestCurrentMonthItem: needActionCurrentMonth,
      latestLastMonthItem: needActionLastMonth,
    } = getLatestPeriodDataOfCurrentAndLastMonth(needAction);
    const {
      latestCurrentMonthItem: onWatchCurrentMonth,
      latestLastMonthItem: onWatchLastMonth,
    } = getLatestPeriodDataOfCurrentAndLastMonth(onWatch);
    const {
      latestCurrentMonthItem: healthyCurrentMonth,
      latestLastMonthItem: healthyLastMonth,
    } = getLatestPeriodDataOfCurrentAndLastMonth(healthy);
    return [
      {
        severity: "NEED_ACTION",
        total: needActionCurrentMonth?.total || 0,
        totalLastMonth: needActionLastMonth?.total || 0,
      },
      {
        severity: "WATCH",
        total: onWatchCurrentMonth?.total || 0,
        totalLastMonth: onWatchLastMonth?.total || 0,
      },
      {
        severity: "HEALTHY",
        total: healthyCurrentMonth?.total || 0,
        totalLastMonth: healthyLastMonth?.total || 0,
      },
    ];
  }, [alertChartData]);

  const [tab, setTab] = useState(0);
  const severityViewSelected = useMemo(() => {
    switch (tab) {
      case 0:
        return "COMBINED";
      case 1:
        return "HEALTHY";
      case 2:
        return "WATCH";
      case 3:
        return "NEED_ACTION";
      default:
        return "COMBINED";
    }
  }, [tab]);
  const tabProps = useCallback((index) => {
    return {
      id: `alert-matching-chart-severity-tab-${index}`,
      "aria-controls": `alert-matching-chart-tabpanel-${index}`,
      className: "alert-matching-chart-severity-tab",
    };
  }, []);
  const onTabChange = useCallback((event, newValue) => {
    setTab(newValue);
  }, []);

  const totalContactPerformance = useMemo(() => {
    if (!alertMatchingHistories)
      return {
        total: 0,
        totalLastMonth: 0,
      };
    const { general } = alertMatchingHistories;
    const {
      latestCurrentMonthItem: generalCurrentMonth,
      latestLastMonthItem: generalLastMonth,
    } = getLatestPeriodDataOfCurrentAndLastMonth(general);
    return {
      total: generalCurrentMonth?.total || 0,
      totalLastMonth: generalLastMonth?.total || 0,
    };
  }, [alertMatchingHistories]);

  const isSearching = useMemo(() => {
    return isGeneraralContactFetching || isContactByAlertFetching;
  }, [isGeneraralContactFetching, isContactByAlertFetching]);

  return (
    <ContactContext.Provider
      value={{
        handleDialog,
        handleShareDetailDialog,
        selectedContactAlert,
        setSelectedContactAlert,
        handleSelectContactAlert,
        isAlertTotalItemSelected,
        setIsAlertTotalItemSelected,
        alertData,
        setAlertData,
        handleAlertDialog,
        handleCreateAlert,
        handleUpdateAlert,
        selectedReviewAlertUserId,
        setSelectedReviewAlertUserId,
        handleReviewAlertsDialog: handleReviewAlerts,
      }}
    >
      <HeaderComponent
        headerContent={
          <Tabs
            className="alert-matching-chart-tabs"
            TabIndicatorProps={{ style: { background: primaryColor } }}
            value={tab}
            onChange={onTabChange}
            aria-label="alert-matching-chart-aria-label"
          >
            <Tab value={0} label="Combined" {...tabProps(0)} />
            <Tab value={1} label="Healthy" {...tabProps(1)} />
            <Tab value={2} label="Watch" {...tabProps(2)} />
            <Tab value={3} label="Need action" {...tabProps(1)} />
          </Tabs>
        }
      />
      <div className="contact-page">
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={isSnackbarOpen}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          message={snackMessage}
        />
        <FilePreviewDialog
          contactList={createContactDTOs}
          isOpen={isOpenPreview}
          handleOpenDialog={handleOpenDialog}
          handleSubmit={handleSubmit}
          isRetry={isRetry}
          isLoading={isLoading}
        />
        <AlertMatchingHistorySection
          alertMatchingsChartData={{
            data: alertChartData,
            isError,
            isMatchingAlertLoading,
            severityView: severityViewSelected,
          }}
          alertMatchingBySeveritySummaryItems={alertMatchingTotalSummaries}
        />
        <ContactAlert
          alertList={alerts}
          totalAlertHit={totalAlertHit}
          totalAlertLastMonth={totalAlertHitLastMonth}
          totalContact={totalContactPerformance.total}
          totalContactLastMonth={totalContactPerformance.totalLastMonth}
        />
        <div className="contact__action-container">
          {/* contact table toolbar */}
          <div className="contact-table-toolbar">
            <FormControl style={{ minWidth: "200px" }}>
              <InputLabel>Action</InputLabel>
              <Select
                label="Action"
                value=""
                onChange={handleEmailEditorDialog}
                disabled={!checkedEmails.length}
              >
                <div>
                  {menuItems.map((item, index) => [
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>,
                    index === 0 && <Divider />,
                    index === 1 && (
                      <>
                        <Divider />
                        <Typography className="email-dialog__auto-follow-up">
                          Send auto follow up til connected
                        </Typography>
                      </>
                    ),
                  ])}
                </div>
              </Select>
            </FormControl>
            <ContactSearchInput
              loading={isSearching}
              onSearch={onContactSearch}
            />
          </div>
          <ImportContactButton onClick={handleFileChange} />
        </div>
        <ContactTable
          tableHeader={columns}
          contactList={buildContactListData}
          refetch={refetch}
          total={totalContacts}
          onChange={onContactTableChange}
          page={contactPage}
          rowsPerPage={contactLimit}
          contactMapByAlerts={contactAlertsMap}
          selectedAlert={selectedContactAlert}
          selectedEmails={checkedEmails}
          handleCheckAccount={handleCheckAccount}
          searchQuery={searchContact}
        />
      </div>
      <ContactAlertDialog
        isOpen={isOpenAlertDialog}
        handleOpenDialog={handleAlertDialog}
        isCompanyAdmin={isCompanyAdmin}
      />
      <ShareDetailsDialog
        contactId={selectedContact}
        isOpen={isOpenShareDetailDialog}
        handleOpenDialog={handleShareDetailDialog}
      />
      <EmailEditorDialog
        isOpen={isOpenEmailEditorDialog}
        emailList={checkedEmails}
        period={period}
        setIsOpenEmailEditorDialog={setIsOpenEmailEditorDialog}
      />
      <ReviewAlertsDialog
        alertList={alerts}
        handleOpenDialog={handleReviewAlerts}
        isOpen={isReviewAlertDialogOpen}
        contactAlertMap={contactAlertsMap}
      />
    </ContactContext.Provider>
  );
};

ContactPage.propTypes = {};

export default ContactPage;

/* eslint-disable import/order */
import { Autocomplete, Dialog, DialogActions, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import "react-awesome-query-builder/lib/css/compact_styles.css";
import "react-awesome-query-builder/lib/css/styles.css";
import { CONFIRMATION_STATUS, CONFIRMATION_TYPE } from "../../commons/enum";
import CustomButton from "../../components/custom-button";
import { useCompanyInfo, useCompanyList } from "../../hooks/company.hooks";
import { useStaffInfo, useStaffList } from "../../hooks/staff.hooks";
import { useCreateUserConfirmation } from "../../hooks/user-confirmation.hooks";
import "../../scss/query-builder.scss";

const AutocompleteInput = ({
  value = {},
  itemList = [],
  inputLabel = "",
  onChange = () => {},
}) => {
  return (
    <div className="autocomplete-input">
      <div className="autocomplete-input__title">{inputLabel}</div>
      <Autocomplete
        value={value}
        selectOnFocus
        clearOnBlur
        renderOption={(props, option) => {
          return (
            <li {...props} key={option?.id || option?.name}>
              {option?.name}
            </li>
          );
        }}
        handleHomeEndKeys
        onChange={(e, val) => {
          onChange(val);
        }}
        getOptionLabel={(option) => option.name || ""}
        id="combo-box-demo"
        options={itemList}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="" />}
      />
    </div>
  );
};

const ShareDetailsDialog = ({
  contactId = "",
  isOpen = false,
  handleOpenDialog = () => {},
  user = {},
}) => {
  const { fullName: userFullname = "" } = user;
  const { data: companyList } = useCompanyList();
  const { data: staffList } = useStaffList();
  const { data: staffInfo } = useStaffInfo();
  const { data: companyInfo } = useCompanyInfo();
  const [staffDetail, setStaffDetail] = useState(null);

  const [companyDetail, setCompanyDetail] = useState(null);
  const createUserConfirmation = useCreateUserConfirmation();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setStaffDetail(null);
  }, [companyDetail]);
  const staffListByCompanyId = useMemo(() => {
    if (!companyDetail || !staffList) return [];
    return staffList
      ?.filter((staff) => staff.companyId === companyDetail?.id)
      .reduce((acc, curr) => {
        const staffListInfo = {
          id: curr.id,
          name: `${curr.user.firstName} ${curr.user.lastName}`.trim(),
        };
        return [...acc, staffListInfo];
      }, []);
  }, [companyDetail, staffList]);

  const handleSendShareDetailsRequest = () => {
    return createUserConfirmation.mutate(
      {
        userId: contactId,
        confirmationType: CONFIRMATION_TYPE.SHARE_DATA,
        status: CONFIRMATION_STATUS.PENDING,
        staffId: staffInfo?.id || "",
        targetStaffId: staffDetail?.id,
        companyName: companyDetail?.name,
        companyDomain: companyInfo.domain,
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Request sent successfully", {
            variant: "success",
          });
          handleOpenDialog();
        },
        onError: (error) => {
          enqueueSnackbar(error?.data?.message, {
            variant: "error",
          });
        },
      }
    );
  };

  const shareDetailContent = useCallback(() => {
    if (Boolean(staffDetail?.name) && Boolean(companyDetail?.name)) {
      return (
        <p className="share-detail__content">
          Send request to share{" "}
          <span className="share-detail__content--bold">{userFullname}</span>{" "}
          details with <br />
          <span className="share-detail__content--bold">
            {staffDetail.name}
          </span>{" "}
          from{" "}
          <span className="share-detail__content--bold">
            {companyDetail.name}
          </span>
        </p>
      );
    }
    return (
      <p className="share-detail__content">
        Select <span className="share-detail__content--bold">Staff</span> to
        share details with.
      </p>
    );
  }, [companyDetail?.name, staffDetail?.name, userFullname]);
  return (
    <Dialog
      onClose={handleOpenDialog}
      aria-labelledby="share-detail-dialog-title"
      className="share-detail-dialog"
      open={isOpen}
    >
      <div className="share-detail__filter">
        <AutocompleteInput
          value={companyDetail}
          inputLabel="Search Companies"
          itemList={companyList}
          onChange={setCompanyDetail}
        />
        <AutocompleteInput
          value={staffDetail}
          inputLabel="Search Staff"
          itemList={staffListByCompanyId}
          onChange={setStaffDetail}
        />
      </div>
      {shareDetailContent()}
      <DialogActions className="share-detail__button-container">
        <CustomButton
          autoFocus
          isLoading={createUserConfirmation.isLoading}
          disabled={!staffDetail?.id || !contactId}
          onClick={handleSendShareDetailsRequest}
          label="Send Request"
        />
      </DialogActions>
    </Dialog>
  );
};

ShareDetailsDialog.propTypes = {};

export default ShareDetailsDialog;

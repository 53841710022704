import { useMutation, useQueries, useQuery } from "react-query";
import { sub } from "date-fns";
import { useMemo } from "react";
import {
  createAlert,
  createContactFilter,
  deleteAlert,
  getAlertList,
  getAlertMatchingHistories,
  getContactFilter,
  getContactList,
  getContactListByAlertId,
  getContactListByFilterId,
  importContactList,
  getSatisfiedContactsByAlertIdWithinGivenContacts,
  updateAlert,
  updateContactFilter,
  createAlertIgnorance,
  deleteAlertIgnorance,
  getIgnoredAlertsByUser,
} from "../api-services/contact";

export const useContactList = (limit = 10, page = 1, q, option = {}) => {
  return useQuery(
    ["contactList", limit, page, q],
    () => getContactList(limit, page, q),
    {
      cacheTime: 0,
      keepPreviousData: true,
      ...option,
    }
  );
};

export const useCreateFilter = () => {
  return useMutation(createContactFilter);
};

export const useUpdateFilter = () => {
  return useMutation(updateContactFilter);
};

export const useContactFilter = () => {
  return useQuery("filterList", getContactFilter);
};

export const useGetContactListByFilter = (id) => {
  return useQuery(["contactListById", id], () => getContactListByFilterId(id), {
    enabled: Boolean(id),
  });
};

export const useImportContactlist = () => {
  return useMutation(importContactList);
};

// Alerts
export const useAlertList = (options) => {
  return useQuery("alertList", getAlertList, {
    cacheTime: 0,
    retry: 0,
    refetchOnWindowFocus: true,
    ...options,
  });
};

export const useCreateAlert = () => {
  return useMutation(createAlert);
};

export const useUpdateAlert = () => {
  return useMutation(updateAlert);
};

export const useDeleteAlert = () => {
  return useMutation(deleteAlert);
};

export const useGetContactListByAlert = (id, limit, page, q, options = {}) => {
  return useQuery(
    ["contactListByAlertId", id, limit, page, q],
    () => getContactListByAlertId(id, limit, page, q),
    {
      enabled: Boolean(id),
      keepPreviousData: true,
      cacheTime: 0,
      ...options,
    }
  );
};

export const useGetSatisfiedContactsByAlertsWithinGivenContacts = (
  alerts = [],
  contactIds
) => {
  const queries = useQueries(
    alerts.map(({ id }) => ({
      queryKey: ["satisfiedContactsByAlertId", id, contactIds],
      queryFn: () =>
        getSatisfiedContactsByAlertIdWithinGivenContacts(id, contactIds),
      enabled: Boolean(id) && contactIds.length > 0,
      keepPreviousData: true,
      cacheTime: 0,
    }))
  );
  const result = {};
  alerts.forEach((alert, index) => {
    const { data, isSuccess } = queries[index];
    if (!isSuccess) return;
    data.data.forEach((contact) => {
      if (!result[contact.id]) {
        result[contact.id] = [];
      }
      result[contact.id].push(alert);
    });
  });
  return result;
};

export const useAlertMatchingHistories = (
  period = "MONTHLY",
  numOfPeriods = 12
) => {
  const toDate = useMemo(() => new Date(), []);
  const fromDate = useMemo(() => {
    let dateFnsUnit = "months";
    if (period === "WEEKLY") {
      dateFnsUnit = "weeks";
    } else if (period === "DAILY") {
      dateFnsUnit = "days";
    }
    return sub(toDate, { [dateFnsUnit]: numOfPeriods });
  }, [numOfPeriods, period, toDate]);

  return useQuery(
    ["alertMatchingHistories", period, numOfPeriods],
    () =>
      getAlertMatchingHistories(
        fromDate.toISOString(),
        toDate.toISOString(),
        period
      ),
    {
      cacheTime: 0,
      keepPreviousData: true,
    }
  );
};

export const useCreateAlertIgnorance = () => {
  return useMutation(createAlertIgnorance);
};

export const useDeleteAlertIgnorance = () => {
  return useMutation(deleteAlertIgnorance);
};

export const useAlertIgnoranceListByUser = (userId, option = {}) => {
  return useQuery(
    ["alertIgnoranceListByUser", userId],
    () => getIgnoredAlertsByUser(userId),
    {
      enabled: Boolean(userId),
      cacheTime: 0,
      ...option,
    }
  );
};

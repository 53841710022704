import { useMemo } from "react";
import { transformToCamelCase } from "../../utils/stuff";
import { IContactAlert } from "./contact-alert-item";

const AlertHitItem: React.FC<{
  alert: IContactAlert;
  faded?: boolean;
  customClassName: string;
}> = ({ alert, faded = false, customClassName = "" }) => {
  const fadedColor = "#868e96";
  const colorVar = useMemo(() => {
    return `var(--${transformToCamelCase(alert.severityCode)}AlertColor)`;
  }, [alert]);
  return (
    <div
      className={`alert-hit-item ${customClassName}`}
      style={{
        borderColor: faded ? fadedColor : colorVar,
      }}
    >
      {alert.name}
    </div>
  );
};
export default AlertHitItem;
